import React, { useEffect, useState } from "react";
import styled from "styled-components";

//imports
import logo from "../../assets/images/logo.svg";
import phone from "../../assets/images/mobile.svg";
import headphone from "../../assets/images/headphone.svg";
import mail from "../../assets/images/message.svg";
import insta from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/face.svg";
import linkedin from "../../assets/images/linkedin.svg";
import youtube from "../../assets/images/youtube.svg";
import insidesLogo from "../../assets/images/classic-logo.svg";
import landphone from "../../assets/images/icons/landphone-sm.svg";
import AccoladesLogo from "../../assets/images/logos/accolades_logo.svg";
import { Link } from "react-router-dom";
import { accountsConfig } from "../../axiosConfig";

export default function Footer() {
  const [contactDetails, setContactDetails] = useState();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    accountsConfig.get("web/list-contact-details/").then((res) => {
      const [data] = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setContactDetails(data);
      } else {
        setContactDetails([]);
      }
    });
  }, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Items>
            <Logo>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </Logo>
            <CopyRight>
              Copyright©{currentYear} Classic Homes All Rights Reserved.
            </CopyRight>
            <LogoLink target="_blank" href="https://accoladesintegrated.com/">
            <p className="lap">Design And Developed By <span>Accolades Integrated</span></p>
              <CompanyLogo src={AccoladesLogo} />
            </LogoLink>
          </Items>
          <Lines></Lines>

          <Items>
            <Label>Office Address</Label>
            <Address>{contactDetails?.address}</Address>
          </Items>
          <Lines></Lines>

          <Items>
            <Label>Quick Links</Label>
            <CoverLinks>
              <Links to="/about">About</Links>
              <Line></Line>
              <Links to="/apartments-for-sale-in-kochi">Apartments</Links>
              <Line></Line>
              <Links to="/villas-for-sale-in-kochi">Villas</Links>
              <Line></Line>
              <Links to="/services">Services</Links>
              <Line></Line>
              <Links to="/blogs">Blog</Links>
            </CoverLinks>
          </Items>
          <Lines className="third-line"></Lines>

          <Items>
            <Label>Contact Us</Label>
            <Cover>
              <Icon>
                <img src={phone} alt="phone-icon" />
              </Icon>
              <Numbers>
                <a href={`tel:${contactDetails?.phone1}`}>
                  {contactDetails?.phone1}
                </a>
                <a href={`tel:${contactDetails?.phone2}`}>
                  {contactDetails?.phone2}
                </a>
              </Numbers>
            </Cover>
            <Cover>
              <Icon>
                <img src={landphone} alt="headphone-icon" />
              </Icon>
              <Numbers>
                <a href={`tel:${contactDetails?.phone3}`}>
                  {contactDetails?.phone3}
                </a>
              </Numbers>
            </Cover>
            <Cover>
              <Icon>
                <img src={mail} alt="mail-icon" />
              </Icon>

              <Numbers>
                <a href={`mailto:${contactDetails?.email1}`}>
                  {contactDetails?.email1}
                </a>
                {/* <a href={`mailto:${contactDetails?.email2}`}>
                  {contactDetails?.email2}
                </a> */}
              </Numbers>
            </Cover>
          </Items>
          <Lines></Lines>

          <Items>
            <Label>Follow</Label>
            <CoverLinks type="soc">
              <a
                href="https://www.instagram.com/classichomes_kochi/"
                target="_blank"
              >
                <SocImg>
                  <img src={insta} alt="instagram-logo" />
                </SocImg>
              </a>
              <a
                href="https://www.facebook.com/classichomesonline/"
                target="_blank"
              >
                <SocImg>
                  <img src={facebook} alt="facebook-logo" />
                </SocImg>
              </a>
              <a
                href="https://www.linkedin.com/company/classic-homes-builders/"
                target="_blank"
              >
                <SocImg>
                  <img src={linkedin} alt="linkedin-logo" />
                </SocImg>
              </a>
              <a
                href="https://www.youtube.com/channel/UC3qkORguoJJE_iJP5gCP1pQ"
                target="_blank"
              >
                <SocImg>
                  <img src={youtube} alt="youtube-logo" />
                </SocImg>
              </a>
            </CoverLinks>
            <InsideLogo>
              <a href="https://classicinsides.com/" target="_blank">
                <img src={insidesLogo} alt="logo" />
              </a>
            </InsideLogo>
          </Items>
        </Content>
        <CopyRightMob>
          Copyright©{currentYear} Classic Homes All Rights Reserved.
        </CopyRightMob>
        <LogoLink
          className="mob"
          target="_blank"
          href="https://accoladesintegrated.com/"
        > <p>Designed and Developed By</p>
        <p className="mob-flex">Accolades Integrated <CompanyLogo src={AccoladesLogo} />
        </p>
        </LogoLink>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #1b1b18;
  color: #dcdcdc;
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 35px;

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: unset;
    .third-line {
      display: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
`;
const Items = styled.div`
  @media (max-width: 1300px) {
    width: 25%;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const Logo = styled.div`
  width: 56%;
  margin-bottom: 20px;
`;
const CopyRight = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const CopyRightMob = styled.div`
  text-align: center;
  border-top: 1px solid var(--line-cl);
  margin: 15px auto;
  padding: 10px;
  width: 70%;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
const Lines = styled.div`
  height: 100px;
  width: 1px;
  background-color: #dcdcdc;
  margin-top: 50px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const Label = styled.div`
  color: #fea566;
  font-family: "gellix-medium";
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const Address = styled.p`
  line-height: 26px;

  @media (min-width: 1601px) {
    width: 20ch;
  }

  @media (max-width: 1600px) {
    width: 170px;
  }

  @media (max-width: 768px) {
    width: 275px;
    text-align: center;
  }
`;
const CoverLinks = styled.div`
  display: ${({ type }) => (type == "soc" ? "flex" : "")};
  gap: 20px;
  margin-bottom: ${({ type }) => (type == "soc" ? "20px" : "")};

  @media (max-width: 768px) {
    display: flex;
  }
  @media (max-width: 580px) {
    gap: 8px;
  }

  @media (max-width: 350px) {
    gap: 5px;
  }
`;
const Links = styled(Link)`
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
  color: #fff;
  transition: 0.4s ease;
  &:hover {
    color: var(--orange);
    transition: 0.4s ease;
  }
`;
const Cover = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  width: 30px;
  margin-right: 10px;

  @media (max-width: 1600px) {
    width: 25px;
    img {
      width: 25px;
    }
  }
`;
const Numbers = styled.div`
  @media (max-width: 768px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  a {
    color: #dcdcdc;
    display: block;
    margin-bottom: 10px;
  }
`;
const SocImg = styled.div`
  width: 30px;
`;
const InsideLogo = styled.div`
  width: 50%;
  @media (max-width: 1300px) {
    width: 150px;
  }
`;

const Line = styled.div`
  background-color: #dcdcdc;
  width: 1px;
  height: 20px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const CompanyLogo = styled.img`
  width: 25px;
  height: auto;
`;

const LogoLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  margin-top: 15px;
  font-size: 14px;
  /* flex-direction: column; */
  gap:8px;
  color:#ffff;

  &.mob {
    display: none;
  }

  @media (max-width: 768px) {
    display: none;
    &.mob {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      align-items: center;
      flex-direction: column;
    }
    .mob-flex{
      display: flex;
      gap: 10px;
      flex-direction: row;
      margin-top: -10px;
    }
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;
